<template>
  <v-card outlined class="pa-5 mt-5">
    <v-card-title>Address information</v-card-title>
    <v-card-text>
      <v-autocomplete
        v-model.lazy="country"
        :rules="[v => !!v || 'This field is required']"
        :items="countries"
        item-text="name"
        item-value="code"
        placeholder="Country"
        name="country"
        required
        outlined
        prepend-inner-icon="mdi-flag"
        hide-no-data
        color="blue"
        autocomplete="off"
        id="address-country"
      ></v-autocomplete>
      <vue-google-autocomplete
        ref="address"
        id="card-address"
        classname="address-autocomplete"
        placeholder="Please type your address"
        @placechanged="getAddressData"
        :country="country"
        required
        @focus="changeInputColor('card-address', 'blue')"
      >
      </vue-google-autocomplete>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            v-model="zip"
            :rules="[v => !!v || 'This field is required']"
            label="ZipCode"
            required
            color="blue"
            id="zip"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { countries } from '../../constants'

export default {
  components: {
    VueGoogleAutocomplete
  },
  data () {
    return {
      country: '',
      address: '',
      zip: '',
      writtenAddress: '',
      streetLine: ''
      //   city: ''
    }
  },
  watch: {
    zip (newVal) {
      this.$store.dispatch('setZipCode', newVal)
    }
  },
  computed: {
    countries () {
      return countries
    }
  },
  methods: {
    changeInputColor (id, color) {
      document.getElementById('card-address').style.borderColor = color
    },
    getAddressData (addressData, placeResultData) {
      if (addressData.country) this.country = countries.find(e => e.name === addressData.country).code
      this.address = `${addressData.route}`
      if (addressData.street_number) this.streetLine = `${addressData.street_number}`
      if (addressData.postal_code) this.zip = addressData.postal_code
      else this.zip = 12345
      this.$emit('setAddress', this.address, this.zip, this.streetLine)
    }
  },
  created () {
    this.$store.dispatch('setWrittenAddress', '')
    this.$store.dispatch('setZipCode', '')
  }
}
</script>

<style>
.address-autocomplete {
  width: 100%;
  height: 56px;
  outline: none;
  border: 0.5px solid #9e9e9e;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 30px;
}
</style>
